import { render, staticRenderFns } from "./InsOrderList.vue?vue&type=template&id=205f8c72&scoped=true&"
import script from "./InsOrderList.vue?vue&type=script&lang=ts&"
export * from "./InsOrderList.vue?vue&type=script&lang=ts&"
import style0 from "./InsOrderList.vue?vue&type=style&index=0&lang=less&"
import style1 from "./InsOrderList.vue?vue&type=style&index=1&id=205f8c72&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205f8c72",
  null
  
)

export default component.exports