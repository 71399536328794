












































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({
  components: {
    SildeBanner: () => import('@/components/base/pc/InsBanner.vue')
  }
})
export default class InsMyCoupon extends Vue {
  currentPage: number = 1;
  pagesize: number = 10;
  SearchInput: any = '';
  value: any = '';
  NewArarry: any[] = [];
  coupons:any[]=[];
  groundColor:string='';
  private status: number = 3;
  private TotalRecord: number = 0;
  private TotalPage: number = 0;
  private AllRecord: number = 0;
  private ActiveRecord: number = 0;
  // 下拉框筛选可用和已用
  // dataFilter (val) {
  //   var that = this;
  //   that.value = val;
  //   that.NewArarry = that.coupons.filter(item => {
  //     return (item.IsExpiry === that.value && (item.IsUsed === that.value || item.IsUsed === !that.value));
  //   });
  // }
  @Watch('status')
  onStatusChange () {
    this.$ShowLayer();
    this.currentPage = 1;
    if (this.status === 2) {
      this.$Api.member.getActiveCoupon({ Page: this.currentPage, PageSize: this.pagesize }).then((result) => {
        this.NewArarry = result.Coupon;
        this.TotalRecord = result.TotalRecord;
        this.TotalPage = result.TotalPage;
        this.$HiddenLayer();
      });
    } else if (this.status === 3) { this.getAllCoupon().then(() => { this.$HiddenLayer(); }); }
  }
  change (currentPage) {
    this.$ShowLayer();
    this.currentPage = currentPage;
    this.getAllCoupon().then(() => { this.$HiddenLayer(); });
  }
  // 页面加载完成后，把数组的值付给新的数组
  created () {
    var that = this;
    this.$Api.member.getActiveCoupon({ Page: this.currentPage, PageSize: this.pagesize }).then((result) => {
      this.ActiveRecord = result.TotalRecord;
    });
    this.getAllCoupon().then(() => {
      this.$HiddenLayer();
      // this.NewArarry = that.coupons;
      // .filter(item => {
      //   return that.NewArarry;
      // });
    });
  }

  // 改变当前页
  currentChange (currentPage) {
    this.currentPage = currentPage;
  }

  // 点击上一页和下一页的时事件
  handleSizeChange (psize) {
    this.pagesize = psize;
  }

  // 计算数组的长度，用于分页和显示总数
  get couponsLength () {
    return this.NewArarry.length;
  }

  // 计算可用优惠券数量;
  get NoUseLength () {
    return this.coupons.filter(item => {
      return item.IsUsed === false && item.IsExpiry === false;
    }).length;
  }

  // 计算已用优惠券数量;
  get UsedLength () {
    return this.coupons.filter(item => {
      return item.IsUsed === true || item.IsExpiry === true;
    }).length;
  }

  getAllCoupon () {
    let that = this;
    return this.$Api.promotion.getAllCoupon({ status: this.status, Page: this.currentPage, PageSize: this.pagesize }).then((result) => {
      if (result) {
        this.NewArarry = result.ReturnValue.Data;
        this.TotalRecord = result.ReturnValue.TotalRecord;
        this.TotalPage = result.ReturnValue.TotalPage;
        this.AllRecord = result.ReturnValue.TotalRecord;
        // that.coupons.forEach(function (i) {
        //   var EffectiveDate = new Date(i.EffectiveDate.replace(/-/g, '/'));
        //   var ExpiryDate = new Date(i.ExpiryDate.replace(/-/g, '/'));
        //   i.EffectiveDate = EffectiveDate.getDate() + '/' + (EffectiveDate.getMonth() + 1) + '/' + EffectiveDate.getFullYear();
        //   i.ExpiryDate = ExpiryDate.getDate() + '/' + (ExpiryDate.getMonth() + 1) + '/' + ExpiryDate.getFullYear();
        // });
      } else {
      }
    }).catch((error) => {
      console.log(error);
    });
  }
  form: any = {
    region: ''
  };
}
