


















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import sdk from '@/sdk/InstoreSdk';
@Component({
  components: {
    InsBanner: () => import('@/components/base/mobile/InsBanner.vue')
  }
})
export default class InsPoints extends Vue {
  activeName: string = 'first';
  currentPage: number = 1;
  pagesize: number = 3;
  dataLength: any = '';
  PointsData:any[]= [];
  TotalNum:any='';
  IncomeNum:any='';
  SpendingNum:any='';
  IncomeData:any[]=[];
  SpendingData:any[]=[];
  tableHeader: Object = {
    background: '#eef1f6',
    color: '#606266'
  };
   MemberName:string='';
   TotalPoints:string='';
   // 改变当前页
   currentChange (currentPage) {
     var that = this;
     that.currentPage = currentPage;
     console.log(currentPage, 'currentPage');
     if (that.activeName === 'first') {
       this.getAllDetail();
     }
     if (that.activeName === 'second') {
       this.getIncomePoints();
     }
     if (that.activeName === 'third') {
       this.getSpendingPoints();
     }
   }
   // 点击上一页和下一页的时事件
   handleSizeChange (psize) {
     this.pagesize = psize;
   }
   // 获取会员信息
   getMemberInfo () {
     let _this = this;
     sdk.api.member.getAccount().then(
       function (data) {
         _this.MemberName = data.FullName;
       }
     );
   }

   // 获取会员总积分
   getMemberTotal () {
     this.$Api.pointsApi.GetMemberTotalPointsInfo().then((result) => {
       this.TotalPoints = result.data.TotalPoints;
     });
   }

   getAllDetail () {
     return this.$Api.pointsApi.GetPointslDetail({ Mode: 0 }).then((result) => {
       let r = result.data.Data;
       this.PointsData = r.length ? r.slice((this.currentPage - 1) * this.pagesize, this.currentPage * this.pagesize) : [];
       this.TotalNum = result.data.TotalRecord;
     });
   }

   getIncomePoints () {
     return this.$Api.pointsApi.GetPointslDetail({ Mode: 1 }).then((result) => {
       let s = result.data.Data;
       this.IncomeData = s.length ? s.slice((this.currentPage - 1) * this.pagesize, this.currentPage * this.pagesize) : [];
       this.IncomeNum = result.data.TotalRecord;
     });
   }

   getSpendingPoints () {
     return this.$Api.pointsApi.GetPointslDetail({ Mode: 2 }).then((result) => {
       let p = result.data.Data;
       this.SpendingData = p.length ? p.slice((this.currentPage - 1) * this.pagesize, this.currentPage * this.pagesize) : [];
       this.SpendingNum = result.data.TotalRecord;
       console.log(result, 'result');
       console.log(this.SpendingData, 'PointsData');
     });
   }

   async created () {
     // 初始化的时候获取第一个的分页总数
     let that = this;
     this.getMemberInfo();
     this.getMemberTotal();
     this.getAllDetail().then(reslut => {
       if (that.activeName === 'first') {
         that.dataLength = that.TotalNum;
       }
       this.$HiddenLayer();
     });
     this.getIncomePoints().then(reslut => {
       if (that.activeName === 'second') {
         that.dataLength = that.IncomeNum;
       }
     });
     this.getSpendingPoints().then(reslut => {
       if (that.activeName === 'third') {
         that.dataLength = that.SpendingNum;
       }
     });
   }
  // 通过监听Tab的变化，获取不同的数组的总数来分页
  @Watch('activeName', { deep: true })
   function (o, n) {
     let that = this;
     if (that.activeName === 'first') {
       this.getAllDetail();
       that.dataLength = that.TotalNum;
     }
     if (that.activeName === 'second') {
       this.getIncomePoints();
       that.dataLength = that.IncomeNum;
     }
     if (that.activeName === 'third') {
       this.getSpendingPoints();
       that.dataLength = that.SpendingNum;
     }
   }
}
